@import "../../themes.scss";

.LogoImage {
  //height: 100px;
  margin-top: 10px;
  width: 90%;
  max-width: 90px;
  padding: 5px;
  text-align: center;
  margin-bottom: 25px;
  position: absolute;
  top: 0;
  margin-top: 20px;
  //margin-right: 10px;

  //margin-bottom: 20px;
  // border-color: red;
  // border-width: 2;
  // border-style: solid;
  //width: $titleColor;
  //font-family: $titleFont;
  //font-size: 34px;
  height: auto;
}

@media (max-height: 600px) {
  .LogoImage {
    max-width: 40px !important;
  }
}
@media (max-height: 700px) {
  .LogoImage {
    max-width: 60px;
    margin-top: 5px;
  }
}
