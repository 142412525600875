@import "../../themes.scss";

.FullScreen {
  width: 100%;
  min-height: calc(100vh);
  align-items: flex-start;
  background-color: whitesmoke;
  //display: flex;
  //flex-direction: column;
  //justify-content: center;
  //align-items: center;
}

.ButtonText {
  //color: $mainColor;
  background-color: $mainColor;
  color: white;
  //background-color: rgba(107, 33, 156, 0.85);
  font-size: 15px !important;
  font-weight: 800 !important;
  font-family: $inputFont !important;
  padding: 3px;
  padding-left: 20px;
  padding-right: 20px;
  //padding: 20px;
  //   padding: 10px;
  //   padding-left: 20px;
  //   padding-right: 20px;
  border: solid 2px $mainColor;
  border-radius: 25px;
  //display: flex;
  //width: 250px;
  //height: 115px;
  margin-top: 10px;
}

.ButtonText:hover {
  cursor: pointer;
}

.LeftRowItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

.RightRowItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .LeftRowItem {
    justify-content: flex-end;
    //padding-right: 10%;
  }
  .RightRowItem {
    justify-content: flex-start;
    //transform: translateY(45px);
    //padding-left: 40px;
  }
}

.LogoImage {
  //height: 100px;
  width: 90%;
  max-width: 400px;
  padding: 5px;
  text-align: center;
  margin-right: 10px;

  //margin-bottom: 20px;
  // border-color: red;
  // border-width: 2;
  // border-style: solid;
  //width: $titleColor;
  //font-family: $titleFont;
  //font-size: 34px;
}

.LogoText {
  margin-bottom: 10px;
  //color: #9125f2;
  color: $mainColor;
  font-family: $titleFont;
  font-size: 60px;
  opacity: 0;
  transition: 1000ms all;
  //display: flex;
  //align-items: center;
  //justify-content: flex-start;
}

.QuestionText {
  color: $mainColor;
  font-size: 32px;
  font-family: $workingFont;
  font-weight: lighter;
  transform: translateY(-50px);
}

.AnswerText {
  color: $mainColor;
  //background-color: white;
  //background-color: $mainColor;
  //color: white;
  //background-color: rgb(107, 33, 156);
  font-size: 28px;
  font-weight: bold;
  font-family: $workingFont;
  padding: 20px;
  //   padding: 10px;
  //   padding-left: 20px;
  //   padding-right: 20px;
  //border: solid 1px $mainColor;
  border-radius: 15px;
  //display: flex;
  width: 510px;
  max-width: 90%;
  //height: 115px;
  margin-top: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  transform: translateY(-50px);
}

// .AnswerText:hover {
//   cursor: pointer;
//   .AnswerSubtext {
//     display: none;
//   }
//   .AnswerSubicon {
//     display: block;
//   }
// }

// .AnswerText:active {
//   background-color: whitesmoke;
//   .AnswerSubtext {
//     display: none;
//   }
//   .AnswerSubicon {
//     display: block;
//   }
// }

.CardElementWrapper {
  background-color: white;
  padding: 8px;
  border: solid 1px lightgray;
  width: 90%;
  border-radius: 3px;
}

.AnswerSubtext {
  //color: black;
  //font-weight: lighter;
  font-size: 12px;
  //opacity: 0.7;
  color: #444444;
  font-weight: bold;
  text-align: left;
  width: 90%;
  margin-top: 10px;
  margin-bottom: 5px;
  //font-family: $inputFont;
  //margin-top: 5px;
}

.AnswerSubicon {
  font-size: 12px;
  //opacity: 0.7;
  display: none;
  width: 245px;
}

.SubQuestionText {
  color: #444444;
  opacity: 0.88;
  font-size: 14px;
  font-weight: lighter;
  font-family: $workingFont;
  margin-bottom: 20px;
}
