@import "../../themes.scss";

.LoadingIconContainer {
  margin: 5px;
  margin-top: 15px;
  width: 240px;
  color: $mainColor;
}

.ButtonContainer {
  margin: 5px;
  margin-top: 0px;
  width: 240px;
}

.ResendLinkButton {
  width: 150px !important;
  height: 44px !important;
  border-radius: 22px !important;
  font-size: 15px !important;
  background-color: white !important;
  color: $successColor !important;
  //   background-color: $successColor !important;
  color: $mainColor !important;
  font-weight: 800 !important;
  font-family: $inputFont !important;
  text-align: center !important;
  vertical-align: middle !important;
  padding: 0 !important;
  border: solid;
  border-width: 1px;
  border-color: $mainColor;
  //border-color: $successColor;
}

.ResendLinkButton:hover,
.ResendLinkButton:active,
.ResendLinkButton:focus {
  //border-color: $successColor !important;
  border-color: $mainColor !important;
}

.ToLoginButton {
  width: 150px !important;
  height: 44px !important;
  border-radius: 22px !important;
  font-size: 15px !important;
  background-color: white !important;
  color: $successColor !important;
  //   background-color: $successColor !important;
  //color: $mainColor !important;
  font-weight: 800 !important;
  font-family: $inputFont !important;
  text-align: center !important;
  vertical-align: middle !important;
  padding: 0 !important;
  border: solid;
  border-width: 1px;
  //border-color: $mainColor;
  border-color: $successColor;
}

.ToLoginButton:hover,
.ToLoginButton:active,
.ToLoginButton:focus {
  border-color: $successColor !important;
  //border-color: $mainColor !important;
}
