@import "../../themes.scss";

.FullScreen {
  width: 100%;
  min-height: 100vh;
  background-color: whitesmoke;
  //display: flex;
  //flex-direction: column;
  //justify-content: center;
  //align-items: center;
}

.LoginContainer {
  width: auto;
  //flex-direction: row;
}

.LeftRowItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

.RightRowItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .LeftRowItem {
    justify-content: flex-end;
    padding-bottom: 20px;
    //padding-right: 10%;
  }
  .RightRowItem {
    justify-content: flex-start;
    padding-left: 40px;
  }
}

.LogoImage {
  //height: 100px;
  width: 460px;
  padding: 5px;

  //margin-bottom: 20px;
  // border-color: red;
  // border-width: 2;
  // border-style: solid;
  //width: $titleColor;
  //font-family: $titleFont;
  //font-size: 34px;
}
.LoginForm {
  width: auto;
  //flex-direction: column;
  //height: 350px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $mainColor;
  padding: 2rem;
  //padding-bottom: 0;
  border-radius: 1rem;
  transition: height 400ms;
  display: inline-block;
}

.Title {
  margin-bottom: 10px;
  color: $titleColor;
  font-family: $titleFont;
  font-size: 34px;
}

.FailedLoginMessage {
  background-color: $errorColor;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  font-size: 12px;
  color: white;
  border-radius: 10px;
  padding: 8px;
  margin-top: 10px;
  width: 250px;
}

.LoginFormWithSignUp {
  //height: 350px;
}

.FormInput {
  margin: 10px;
}

.InputContainer {
  margin: 10px;
  margin-bottom: 0px;
  width: 240px;
}

.ButtonContainer {
  margin-top: 20px;
  width: 240px;
}

.ToggleButtonContainer {
  margin-top: 6px;
}

.ToggleSignupButton {
  color: white !important;
  font-family: "Raleway", sans-serif !important;
  font-size: 12px !important;
}

.ToggleSignupButton:hover {
  color: white !important;
  font-family: "Raleway", sans-serif !important;
  text-decoration: underline !important;
  cursor: pointer;
}

.ForgotPasswordButton {
  color: grey !important;
  opacity: 0.6 !important;
  font-size: 12px;
  font-family: "Roboto Regular";
  //textDecorationLine: 'underline',
}

.ForgotPasswordButtonUnderlined {
  color: grey !important;
  opacity: 0.6 !important;
  font-size: 12px;
  font-family: "Roboto Regular";
  margin-left: 3px;
  text-decoration: underline !important;
}

.ForgotPasswordButtonUnderlined:hover {
  cursor: pointer;
  opacity: 0.9 !important;
}

.ForgotPasswordButtonContainer {
  margin-top: 3px;
  width: 240px;
}
