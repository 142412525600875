@import "../../themes.scss";

.ButtonContainer {
  margin: 5px;
  margin-top: 15px;
  width: 240px;
}

.SubmitButton {
  //margin: 10px !important;
  //margin-top: 10px;
  width: 100px !important;
  height: 44px !important;
  border-radius: 22px !important;
  font-size: 15px !important;
  background-color: white;
  color: $mainColor;
  font-weight: 800 !important;
  font-family: $inputFont !important;
  transition: width 500ms;
  text-align: center !important;
  vertical-align: middle !important;
  padding: 0 !important;
  border-color: $mainColor !important;
}

.SubmitButton:hover {
  //margin: 10px !important;
  //margin-top: 10px;
  width: 100px !important;
  height: 44px !important;
  border-radius: 22px !important;
  font-size: 15px !important;
  background-color: $mainColor;
  color: "white";
  font-weight: 800 !important;
  font-family: $inputFont !important;
  transition: width 500ms;
  text-align: center !important;
  vertical-align: middle !important;
  padding: 0 !important;
  border-color: $mainColor !important;
}

.SubmitButton:focus {
  //margin: 10px !important;
  //margin-top: 10px;
  width: 100px !important;
  height: 44px !important;
  border-radius: 22px !important;
  font-size: 15px !important;
  background-color: $mainColor !important;
  color: "white";
  font-weight: 800 !important;
  font-family: $inputFont !important;
  transition: width 500ms;
  text-align: center !important;
  vertical-align: middle !important;
  padding: 0 !important;
  border-color: $mainColor !important;
}

.SubmitButton:active {
  //margin: 10px !important;
  //margin-top: 10px;
  width: 100px !important;
  height: 44px !important;
  border-radius: 22px !important;
  font-size: 15px !important;
  background-color: $mainColor !important;
  color: "white";
  font-weight: 800 !important;
  font-family: $inputFont !important;
  transition: width 500ms;
  text-align: center !important;
  vertical-align: middle !important;
  padding: 0 !important;
  border-color: $mainColor !important;
  opacity: 0.8 !important;
}

.BackToLoginButton:hover,
.BackToLoginButton:active,
.BackToLoginButton:focus {
  border-color: $successColor !important;
}

.SubmitButtonSuccess {
  width: 46px !important;
}

.SubmitButton:disabled {
  background-color: white !important;
  color: darkgrey !important;
  opacity: 1 !important;
  cursor: not-allowed;
  font-weight: 600 !important;
}
