@import "../../themes.scss";

.LogoutButton {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  margin-right: 15px;
  color: $mainColor;
  font-size: 14px;
  font-weight: bold;
  opacity: 0.7;
  font-family: $workingFont;
  //   background-color: $mainColor;
  //   border-radius: 20px;
  //   color: white;
  //   padding: 5px;
  //   padding-left: 15px;
  //   padding-right: 15px;
  //   opacity: 0.8;
}

.LogoutButton:hover {
  cursor: pointer;
  opacity: 1;
}

// .FullScreenMessage {
//   width: auto;
//   //flex-direction: column;
//   //height: 350px;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   //background-color: $mainColor;
//   //background-color: $successColor;
//   //background-color: #f5f5f5;
//   padding: 2rem;
//   padding-bottom: 1rem;
//   border-radius: 1rem;
//   transition: height 400ms;
//   display: flex;
//   transition: all 1s;
//   // border-width: 1px !important;
//   // border-color: $mainColor !important;
//   // border: solid;
// }

// .Title {
//   margin-bottom: 6px;
//   //color: $titleColor;
//   //color: $mainColor;
//   //color: #ffffff;
//   color: $successColor;
//   font-family: $workingFont;
//   font-size: 30px;
//   font-weight: bold;
// }

// .Subtitle {
//   margin-bottom: 2px;
//   color: grey;
//   font-family: $workingFont;
//   font-size: 16px;
//   font-weight: lighter;
// }

// .ErrorText {
//   color: black !important;
// }

// .ErrorIcon {
//   color: $errorColor !important;
//   opacity: 0.9;
// }

// .FailedMessage {
//   background-color: $errorColor;
//   font-family: $inputFont;
//   font-weight: bold;
//   font-size: 12px;
//   color: white;
//   border-radius: 10px;
//   padding: 8px;
//   margin-top: 10px;
// }

// .FormInput {
//   margin: 10px;
// }

// .InputContainer {
//   margin: 10px;
//   margin-bottom: 1px;
//   width: 240px;
// }

// .IconContainer {
//   margin: 5px;
//   margin-top: 15px;
//   width: 240px;
//   color: $successColor;
// }
