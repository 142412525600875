@import "../../themes.scss";

.ForgotForm {
  width: auto;
  //flex-direction: column;
  //height: 350px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $mainColor;
  padding: 2rem;
  border-radius: 1rem;
  transition: height 400ms;
  display: flex;
  transition: all 1s;
}

.TitleTopLine {
  margin-bottom: 3px;
  color: $titleColor;
  font-family: $workingFont;
  font-size: 30px;
  font-weight: medium;
  line-height: 1;
}
.Title {
  margin-bottom: 10px;
  color: $titleColor;
  font-family: $workingFont;
  font-size: 30px;
  font-weight: medium;
  line-height: 1;
}

.FailedResetMessage {
  background-color: $errorColor;
  font-family: $inputFont;
  font-weight: bold;
  font-size: 12px;
  color: white;
  border-radius: 10px;
  padding: 8px;
  margin-top: 10px;
  width: 250px;
}

.FormInput {
  margin: 10px;
}

.InputContainer {
  margin: 10px;
  margin-bottom: 1px;
  width: 240px;
}

.ButtonContainer {
  margin: 5px;
  margin-top: 15px;
  width: 240px;
}

.SubmitButton {
  //margin: 10px !important;
  //margin-top: 10px;
  width: 100px !important;
  height: 44px !important;
  border-radius: 22px !important;
  font-size: 15px !important;
  background-color: white !important;
  color: $mainColor !important;
  font-weight: 800 !important;
  font-family: $inputFont !important;
  transition: width 500ms;
  text-align: center !important;
  vertical-align: middle !important;
  padding: 0 !important;
}

.BackToLoginButton {
  width: 150px !important;
  height: 44px !important;
  border-radius: 22px !important;
  font-size: 15px !important;
  background-color: white !important;
  color: $successColor !important;
  //   background-color: $successColor !important;
  //   color: white !important;
  font-weight: 800 !important;
  font-family: $inputFont !important;
  text-align: center !important;
  vertical-align: middle !important;
  padding: 0 !important;
  border: solid;
  border-width: 1px;
  border-color: $successColor;
}

.BackToLoginButton:hover,
.BackToLoginButton:active,
.BackToLoginButton:focus {
  border-color: $successColor !important;
}

.SubmitButtonSuccess {
  width: 46px !important;
}

.SubmitButton:disabled {
  background-color: white !important;
  color: darkgrey !important;
  opacity: 1 !important;
  cursor: not-allowed;
  font-weight: 600 !important;
}

.ToggleSignupButton {
  color: white !important;
  font-family: "Raleway", sans-serif !important;
  font-size: 12px !important;
}

.ToggleSignupButton:hover {
  color: white !important;
  font-family: "Raleway", sans-serif !important;
  text-decoration: underline !important;
  cursor: pointer;
}
