@import "../../themes.scss";

.ResetForm {
  width: auto;
  //flex-direction: column;
  //height: 350px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $mainColor;
  padding: 2rem;
  border-radius: 1rem;
  transition: height 400ms;
  display: flex;
  transition: all 1s;
}

.Title {
  margin-bottom: 10px;
  color: $titleColor;
  font-family: $workingFont;
  font-size: 30px;
  font-weight: medium;
}

.FailedResetMessage {
  background-color: $errorColor;
  font-family: $inputFont;
  font-weight: bold;
  font-size: 12px;
  color: white;
  border-radius: 10px;
  padding: 8px;
  margin-top: 10px;
  width: 250px;
}

.FormInput {
  margin: 10px;
}

.InputContainer {
  margin: 10px;
  margin-bottom: 1px;
  width: 240px;
}

.ButtonContainer {
  margin: 5px;
  margin-top: 15px;
  width: 240px;
}

.ToggleSignupButton {
  color: white !important;
  font-family: "Raleway", sans-serif !important;
  font-size: 12px !important;
}

.ToggleSignupButton:hover {
  color: white !important;
  font-family: "Raleway", sans-serif !important;
  text-decoration: underline !important;
  cursor: pointer;
}
