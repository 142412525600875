@import "../../themes.scss";

.FullScreenMessage {
  width: auto;
  //flex-direction: column;
  //height: 350px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //background-color: $mainColor;
  //background-color: $successColor;
  //background-color: #f5f5f5;
  padding: 0.5rem;
  border-radius: 1rem;
  transition: height 400ms;
  display: flex;
  transition: all 1s;
  // border-width: 1px !important;
  // border-color: $mainColor !important;
  // border: solid;
}

.Title {
  margin-bottom: 10px;
  //color: $titleColor;
  //color: $mainColor;
  //color: #ffffff;
  color: $mainColor;
  font-family: $workingFont;
  font-size: 44px;
  font-weight: bold;
}

.SubHeading {
  margin-bottom: 0px;
  padding-bottom: 0;
  //color: $titleColor;
  //color: $mainColor;
  //color: #ffffff;
  color: #444444;
  font-family: $workingFont;
  font-size: 22px;
  //font-weight: bold;
  align-self: flex-start;
  margin-left: 10%;
  margin-right: 10%;
  text-align: left;
}

.Subtitle {
  margin-bottom: 2px;
  color: grey;
  font-family: $workingFont;
  font-size: 16px;
  font-weight: lighter;
  text-align: left;
  margin-left: 10%;
  margin-right: 10%;
  align-self: flex-start;
}

.ErrorText {
  color: black !important;
}

.ErrorIcon {
  color: $errorColor !important;
  opacity: 0.9;
}

.FailedMessage {
  background-color: $errorColor;
  font-family: $inputFont;
  font-weight: bold;
  font-size: 12px;
  color: white;
  border-radius: 10px;
  padding: 8px;
  margin-top: 10px;
}

.FormInput {
  margin: 10px;
}

.InputContainer {
  margin: 10px;
  margin-bottom: 1px;
  width: 240px;
}

.IconContainer {
  margin: 5px;
  margin-top: 15px;
  width: 240px;
  color: $successColor;
}
