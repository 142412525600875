@import "../../themes.scss";

.FullScreenMessage {
  width: auto;
  //flex-direction: column;
  //height: 350px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //background-color: $mainColor;
  //background-color: $successColor;
  //background-color: #f5f5f5;
  padding: 0.5rem;
  border-radius: 1rem;
  transition: height 400ms;
  display: flex;
  transition: all 1s;
  // border-width: 1px !important;
  // border-color: $mainColor !important;
  // border: solid;
}

.Title {
  margin-bottom: 6px;
  //color: $titleColor;
  //color: $mainColor;
  //color: #ffffff;
  color: $mainColor;
  font-family: $workingFont;
  font-size: 30px;
  font-weight: bold;
}

.Subtitle {
  margin-bottom: 2px;
  color: grey;
  font-family: $workingFont;
  font-size: 16px;
  font-weight: lighter;
  margin-left: 10%;
  margin-right: 10%;
}

.ErrorText {
  color: black !important;
}

.ErrorIcon {
  color: $errorColor !important;
  opacity: 0.9;
}

.FailedMessage {
  background-color: $errorColor;
  font-family: $inputFont;
  font-weight: bold;
  font-size: 12px;
  color: white;
  border-radius: 10px;
  padding: 8px;
  margin-top: 10px;
}

.FormInput {
  margin: 10px;
}

.InputContainer {
  margin: 10px;
  margin-bottom: 1px;
  width: 240px;
}

.IconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  // border: solid;
  // border-width: 1px;
  // border-color: red;
  margin-right: 8px;
  margin-left: 8px;
}

.IconContainer:hover {
  cursor: pointer;
}

.IconContainerDisabled {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  // border: solid;
  // border-width: 1px;
  // border-color: red;
  margin-right: 8px;
  margin-left: 8px;
}

.IconContainerDisabled:hover {
  cursor: not-allowed;
}

.DonationAmountContainer {
  color: white;
  background-color: rgba($color: $mainColor, $alpha: 0.3);
  border-radius: 6px;
  //opacity: 0.4;
  padding: 10px;
  //padding-right: 5px;
  //padding-left: 15px;
  //margin-right: 5;
  font-size: 30px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  min-width: 100px;
}

.DonationValue {
  font-size: 30px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  color: rgba($color: $mainColor, $alpha: 1);
  font-weight: bold;
}

.PoliciesText {
  font-size: 10px;
  margin-bottom: 10px;
  //width: 170px;
  position: absolute;
  bottom: 0;
  color: #444444;
  opacity: 0.5;
}

.PolicyLink {
  color: $mainColor !important;
}
